<template>
  <div class="table-wrap">
    <div class="component-tit">
      <span>{{ taskInfo.taskName }}</span>
      <a-button type="primary" @click="back">
        {{ $t("go_back") }}
        <!-- 返回 -->
      </a-button>
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :allObj="{ name: $t('CM_All'), id: -1 }"
        :dataSource="statusOptions"
        @onChange="switchStatus"
      >
        <!-- 全部 -->
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="queryParam.nameOrAccount"
              :placeholder="$t('teaching.please_enter_name_account')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="handleSearch"
            />
            <!-- 请输入姓名（账号） -->
          </div>
        </template>
      </ListFilter>
    </div>
    <a-tabs
      class="stage-tabs"
      v-model:activeKey="activeStageKey"
      @change="tabChange"
    >
      <a-tab-pane
        v-for="item in stages"
        :key="item.stageId"
        :tab="item.stageName"
      />
    </a-tabs>
    <a-table
      :scroll="{ x: 884 }"
      :columns="columns"
      :data-source="state.dataSource"
      :rowKey="(record, index) => index"
      :bordered="false"
      :pagination="pagination"
      :loading="state.loading"
      @change="handleTableChange"
    >
      <template #realName="{ record }">
        <OpenData type="userName" :openid="record.realName" />
        ({{ record.account }})
      </template>
      <template #departmentName="{ record }">
        <a-tooltip placement="topLeft">
          <template #title>
            <template
              v-for="(str, strIndex) in record.departmentPath.split('/')"
              :key="strIndex"
            >
              <OpenData type="departmentName" :openid="str" />
              <span
                v-if="strIndex + 1 < record.departmentPath.split('/').length"
                >/</span
              >
            </template>
          </template>
          <OpenData type="departmentName" :openid="record.departmentName" />
        </a-tooltip>
      </template>
      <template #status="{ record }">
        <span style="color: #e8673e" v-if="record.tutorPlan == ''">{{
          $t("teaching.not_arranged")
        }}</span>
        <!-- 未安排 -->
        <span style="color: #44b44f" v-else-if="record.teachPlan == 1">{{
          $t("CM_Completed")
        }}</span>
        <!-- 已完成 -->
        <span style="color: #818181" v-else>{{
          $t("teaching.pending_feedback")
        }}</span>
        <!-- 待反馈 -->
      </template>
      <template #action="{ record }">
        <div class="action">
          <a-button type="link" @click="toDetail(record)">{{
            $t("cm_view")
          }}</a-button>
          <!-- 查看 -->
        </div>
      </template>
    </a-table>
  </div>
  <!-- 安排帮带计划 -->
  <a-modal
    v-model:visible="visible"
    :title="$t('teaching.arrange_a_helping_program')"
    width="700px"
    :maskClosable="false"
  >
    <p>{{ $t("teaching.mentor_program") }}：</p>
    <!-- 导师计划： -->
    <wangeditor
      v-if="!currHasPlan"
      :key="editorKey"
      :placeholder="$t('teaching.arrange_help_input_tip')"
      v-on:getWangEditorValue="getWangEditorValue"
      :editorValue="formData.plan"
    >
      <!-- 请输入您对该学员安排的帮带计划，例如希望学员在帮带期间应参与的培训课程，应掌握和提高的业务技能、能力，应承担的工作，预计达成的目标等 -->
    </wangeditor>
    <div class="wangeditor-box" v-else v-html="formData.plan"></div>
    <p v-if="formData.teachPlan == 1">{{ $t("teaching.arrange_help_tip") }}</p>
    <!-- 学员反馈：学员已确认 -->
    <template #footer>
      <a-button
        @click="
          visible = false;
          editorKey++;
        "
        >{{ $t("CM_Cancel") }}</a-button
      >
      <!-- 取消 -->
      <a-button
        v-if="!currHasPlan"
        type="primary"
        :disabled="formData.plan.trim() === ''"
        @click="submit"
        >{{ $t("CM_Confirm") }}</a-button
      >
      <!-- 确定 -->
    </template>
  </a-modal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw, getCurrentInstance } from "vue";
import { useFetchData } from "@/utils/useFetchData";
import { teachManagePlanList, teachManagePlan } from "@/api/map";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import wangeditor from "@/components/wangeditor/index.vue";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    ListFilter,
    wangeditor,
    OpenData,
  },
  props: {
    taskInfo: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const stateData = reactive({
      statusOptions: [
        { name: $t("teaching.not_arranged"), id: 0 }, // 未安排
        { name: $t("teaching.pending_feedback"), id: 2 }, // 待反馈
        { name: $t("CM_Completed"), id: 1 }, // 已完成
      ],
      stages: [],
      activeStageKey: 0,
      columns: [
        {
          title: $t("teaching.table.name_account"),
          // title: "姓名（账号）",
          dataIndex: "realName",
          slots: { customRender: "realName" },
          width: 250,
          ellipsis: true,
        },
        {
          title: $t("teaching.table.department"),
          // title: "部门",
          width: 200,
          slots: { customRender: "departmentName" },
          ellipsis: true,
        },
        {
          title: $t("teaching.table.resource_name"),
          // title: "资源名称",
          dataIndex: "resourceName",
          width: 200,
          ellipsis: true,
        },
        {
          title: $t("teaching.table.status"),
          // title: "状态",
          width: 150,
          align: "center",
          slots: { customRender: "status" },
        },
        {
          title: $t("teaching.table.operate"),
          // title: "操作",
          width: 130,
          align: "center",
          slots: { customRender: "action" },
        },
      ],
      currHasPlan: false,
      visible: false,
      editorKey: 0,
      formData: {
        taskId: props.taskInfo.taskId,
        detailId: 0,
        userIds: [],
        plan: "",
        teachPlan: 0,
      },
    });

    let queryParam = reactive({
      nameOrAccount: "",
      taskId: props.taskInfo.taskId,
      evaluationStatus: -1,
      planStatus: -1,
      reviewStatus: -1,
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return teachManagePlanList(form).then((res) => {
        if (!queryParam.stageId) {
          stateData.stages = res.data.stages;
          stateData.activeStageKey = res.data.stages[0].stageId;
        }
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 30,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    const handleSearch = () => {
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    const back = () => {
      emit("back");
    };

    const switchStatus = (arr) => {
      queryParam.planStatus = arr[0];
      handleSearch();
    };

    const tabChange = (e) => {
      queryParam.stageId = e;
      handleSearch();
    };

    const toDetail = (item) => {
      stateData.currHasPlan = !!item.tutorPlan;
      stateData.formData = {
        taskId: props.taskInfo.taskId,
        detailId: item.detailId,
        userIds: [item.userID],
        plan: item.tutorPlan,
        teachPlan: item.teachPlan,
      };
      stateData.visible = true;
      stateData.editorKey++;
    };

    const getWangEditorValue = (str) => {
      stateData.formData.plan = str;
    };

    const submit = () => {
      teachManagePlan(stateData.formData).then((res) => {
        reload();
        proxy.$message.success($t("CM_Success"));
        stateData.visible = false;
        stateData.editorKey++;
      });
    };

    return {
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleSearch,
      back,
      switchStatus,
      tabChange,
      toDetail,
      submit,
      getWangEditorValue,
    };
  },
};
</script>

<style lang="less" scoped>
.table-wrap {
  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 16px 0 12px;
    .mixinFlex(space-between; center);
  }
  .filter-wrap {
    padding-bottom: 12px;
    margin-bottom: 16px;

    .filter {
      padding: 5px 0;
    }

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .action {
    ::v-deep(.ant-btn) {
      padding-right: 4px;
      padding-left: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.stage-tabs {
  ::v-deep(.ant-tabs-bar) {
    border-bottom: none;
    .ant-tabs-ink-bar {
      background-color: transparent !important;
    }
    .ant-tabs-tab {
      background-color: #f5f7fb;
      padding: 5px 20px 5px 30px;
      color: #999;
      margin-right: 10px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-left: 9px solid #fff;
        border-bottom: 16px solid transparent;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -9px;
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-left: 9px solid #f5f7fb;
        border-bottom: 16px solid transparent;
      }
      &:first-child {
        &:before {
          content: none;
        }
      }
      &.ant-tabs-tab-active {
        background-color: #36b5fd;
        color: #fff;
        &:hover {
          color: #fff;
        }
        &:after {
          border-left: 9px solid #36b5fd;
        }
      }
    }
  }
}
</style>
<style lang="less">
.wangeditor-box {
  padding: 10px;
  background: #f5f5f5;
  cursor: not-allowed;
  /* table 样式 */
  table {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  table td,
  table th {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
  }
  table th {
    border-bottom: 2px solid #ccc;
    text-align: center;
  }

  /* blockquote 样式 */
  blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }

  /* code 样式 */
  code {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-color: #f1f1f1;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 3px;
  }
  pre code {
    display: block;
  }

  /* ul ol 样式 */
  ul,
  ol {
    margin: 10px 0 10px 20px;
  }
}
</style>
